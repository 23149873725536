import React from "react";
import {Container, Grid, Typography} from "@mui/material";
import {CustomButton} from "../themeButton";
import Fade from "react-reveal";
import {
    CenterWrapper,
    ColumnWrapper,
    RootStyle,
    SubTitle,
    Title
} from "../components";
import {styled} from "@mui/material/styles";
import {Icon} from "@iconify/react";
import {PageLink} from "../Explore";

const Info = () => {
    return (
        <RootStyle>
            <Container maxWidth="lg">
                <Grid container direction="column">
                    <Grid item>
                        <Fade up>
                            <ColumnWrapper>
                                <Title>Get started now</Title>
                                <SubTitle>
                                    Can’t wait to try IWC Exchange? You don’t
                                    have to! Getting started is a matter of
                                    minutes.
                                </SubTitle>
                            </ColumnWrapper>
                        </Fade>
                    </Grid>
                    <Grid item container sx={{paddingTop: "70px"}}>
                        {infoItems.map((infoItem) => (
                            <InfoGrid item xs={12} md={4} key={infoItem.id}>
                                <Fade bottom>
                                    <ColumnWrapper>
                                        <CenterWrapper>
                                            <InfoIcon icon={infoItem.icon} />
                                        </CenterWrapper>
                                        <InfoHeader>
                                            {infoItem.header}
                                        </InfoHeader>
                                        <InfoSubHeader>
                                            {infoItem.subHeader}
                                        </InfoSubHeader>
                                    </ColumnWrapper>
                                </Fade>
                            </InfoGrid>
                        ))}
                    </Grid>
                    <Grid item>
                        <Fade bottom>
                            <CenterWrapper sx={{paddingTop: "60px"}}>
                                <PageLink href="/auth/register">
                                    <InfoButton>Create an account</InfoButton>
                                </PageLink>
                            </CenterWrapper>
                        </Fade>
                    </Grid>
                </Grid>
            </Container>
        </RootStyle>
    );
};

export default Info;

const InfoIcon = styled(Icon)(({theme}) => ({
    fontSize: 120,
    color: theme.palette.primary.light,
    [theme.breakpoints.down("md")]: {
        fontSize: 80
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: 50
    }
}));
const InfoHeader = styled(Typography)(({theme}) => ({
    ...theme.typography.h5,
    paddingTop: 20,
    color: theme.palette.primary.light,
    textAlign: "center"
}));
const InfoSubHeader = styled(Typography)(({theme}) => ({
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    paddingTop: 5,
    textAlign: "center"
}));
const InfoButton = styled(CustomButton)(({theme}) => ({
    width: "30%",
    [theme.breakpoints.down("md")]: {
        width: "60%"
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: 12
    }
}));
const InfoGrid = styled(Grid)(({theme}) => ({
    [theme.breakpoints.down("md")]: {
        "&:not(:last-child)": {
            paddingBottom: 30
        }
    }
}));
const infoItems = [
    {
        id: 1,
        header: "Create your account",
        subHeader: "We’ll guide you through the process.",
        icon: "entypo:add-user"
    },
    {
        id: 2,
        header: "Link to your Bank",
        subHeader: "Buy crypto through our partners or transfer in funds.",
        icon: "mdi:bank-plus"
    },
    {
        id: 3,
        header: "Explore the ecosystem",
        subHeader:
            "See how easy it is to trade, pay and invest with Cryptitan!.",
        icon: "gis:search-globe"
    }
];
