import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Grid,
    Typography,
    Tabs,
    Tab,
    Container
} from "@mui/material";
import {faqItems} from "./data";
import {ArrowDropUp} from "@mui/icons-material";
import PropTypes from "prop-types";
import Fade from "react-reveal";
import {ColumnWrapper, RootStyle, SubTitle, Title} from "../components";
import {styled, useTheme} from "@mui/material/styles";

const FAQ = () => {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handlerChange = (event, newValue) => {
        setValue(newValue);
    };

    function TabPanel(props) {
        const {children, value, index, ...other} = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}>
                {value === index && (
                    <Box sx={{p: 3}}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`
        };
    }

    const [expanded, setExpanded] = React.useState("panel1");
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <RootStyle id="faqs">
            <Container maxWidth="lg">
                <Grid container direction="column">
                    <Grid item sx={{width: "100%"}}>
                        <Fade up>
                            <ColumnWrapper>
                                <Title>Frequently Asked Questions</Title>
                                <SubTitle>
                                    Below we’ve provided a bit of General
                                    Issues, Deposit related, Account related,
                                    and Security Related. If you have any other
                                    questions, please get in touch using the
                                    contact form below.
                                </SubTitle>
                            </ColumnWrapper>
                        </Fade>
                    </Grid>
                    <Grid item sx={{width: "100%"}}>
                        <Fade top>
                            <FaqsContainer
                                sx={{borderBottom: 1, borderColor: "divider"}}>
                                <Tabs
                                    value={value}
                                    onChange={handlerChange}
                                    aria-label="faq tabs">
                                    <FaqsTab
                                        label="General"
                                        {...a11yProps(0)}
                                    />
                                </Tabs>
                            </FaqsContainer>
                        </Fade>
                    </Grid>
                    <Grid item>
                        <TabPanel value={value} index={0}>
                            {faqItems.map((generalItem) => (
                                <FaqsAccordion
                                    expanded={expanded === generalItem.panel}
                                    onChange={handleChange(generalItem.panel)}
                                    key={generalItem.id}>
                                    <FaqsAccordionSummary
                                        aria-controls={
                                            generalItem.togglerId + "d-content"
                                        }
                                        id={generalItem.togglerId + "d-header"}
                                        expandIcon={
                                            <ArrowDropUp
                                                sx={{
                                                    color: theme.palette.primary
                                                        .main
                                                }}
                                            />
                                        }>
                                        {generalItem.question}
                                    </FaqsAccordionSummary>
                                    <FaqsAccordionDetails>
                                        {generalItem.answer}
                                    </FaqsAccordionDetails>
                                </FaqsAccordion>
                            ))}
                        </TabPanel>
                    </Grid>
                </Grid>
            </Container>
        </RootStyle>
    );
};

export default FAQ;

const FaqsContainer = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    margin: "20px 0"
}));
const FaqsTab = styled(Tab)(({theme}) => ({
    color: theme.palette.primary.main,
    "&:not(:first-of-type)": {
        marginLeft: 50
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        "&:not(:first-of-type)": {
            marginLeft: 0
        }
    }
}));
const FaqsAccordion = styled(Accordion)(({theme}) => ({
    marginBottom: 10,
    padding: "10px 0",
    border: `1px solid ${theme.palette.grey[500]}`,
    boxShadow: theme.shadows[2],
    "&.Mui-expanded": {
        border: `2px solid ${theme.palette.primary.main}`
    }
}));
const FaqsAccordionSummary = styled(AccordionSummary)(({theme}) => ({
    ...theme.typography.body2,
    color: theme.palette.text.primary
}));
const FaqsAccordionDetails = styled(AccordionDetails)(({theme}) => ({
    ...theme.typography.body1,
    color: theme.palette.grey[500]
}));
