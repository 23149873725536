import React from "react";
import {SubHeader, CentreWrapper, ColumnWrapper} from "../components";
import {Box, Stack} from "@mui/material";
import {styled} from "@mui/material/styles";

const Tabcontent = ({data}) => {
    return (
        <Stack
            sx={{marginTop: "50px"}}
            direction={{xs: "column", md: "row"}}
            spacing={6}>
            {data.map((dataItem, index) => (
                <ColumnWrapper key={index}>
                    <CentreWrapper>
                        {dataItem.icon}
                        <Header>{dataItem.title}</Header>
                    </CentreWrapper>
                    <SubHeader sx={{textAlign: "left"}}>
                        {dataItem.subtitle}
                    </SubHeader>
                </ColumnWrapper>
            ))}
        </Stack>
    );
};

const Header = styled(Box)(({theme}) => ({
    ...theme.typography.h5,
    paddingTop: 20,
    color: theme.palette.primary.light,
    textAlign: "center"
}));
export default Tabcontent;
