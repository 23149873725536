import React, {useState} from "react";
import {Box, Container, Grid, Typography} from "@mui/material";
import {CustomButton} from "../themeButton";
import Waves from "./component/bannerWaves";
import BackgroundImage from "./component/bannerBackground";
import Fade from "react-reveal";
import {styled} from "@mui/material/styles";
import bannerImage from "../../../assets/images/bannerImg.png";

const Banner = () => {
    const [email, setEmail] = useState("");
    return (
        <BannerStyle id="home">
            <Container maxWidth="lg">
                <Grid container alignItems="center">
                    <Grid item xs={12} md={6}>
                        <Fade up>
                            <BannerContent>
                                <BannerTitle>INCREDIBLE EXCHANGE</BannerTitle>
                                <BannerSubtitle>
                                    The ultimate game changer WEB3.0 digital
                                    asset trading network.
                                </BannerSubtitle>
                                <BannerParagraph>
                                    Incredible Exchange is a Multi - Features
                                    crypto currency exchange ecosystem with
                                    support for multiple Crypto currencies. You
                                    are able to host Trade, buy & Sell with
                                    direct bank transfer or credit card with our
                                    unique multiple payment gateways.
                                </BannerParagraph>
                                <SubscribeSection
                                    action="https://IwcExchange.us9.list-manage.com/subscribe/post?u=9dbc2e806bdd330529c070d62&amp;id=45996e53fa&amp;f_id=00000be1f0"
                                    method="post"
                                    id="mc-embedded-subscribe-form"
                                    name="mc-embedded-subscribe-form"
                                    target="_blank"
                                    noValidate>
                                    <TextField
                                        placeholder="Email"
                                        type="email"
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                        }}
                                        name="EMAIL"
                                        id="mce-EMAIL"
                                    />
                                    <BannerButton
                                        type="submit"
                                        value="Subscribe"
                                        name="subscribe"
                                        id="mc-embedded-subscribe">
                                        Subscribe
                                    </BannerButton>
                                </SubscribeSection>
                            </BannerContent>
                        </Fade>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{display: {xs: "none", md: "block"}}}>
                            <ImageWrapper>
                                <Image src={bannerImage} />
                            </ImageWrapper>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <BackgroundImage />
            <Waves />
        </BannerStyle>
    );
};

export default Banner;
const BannerStyle = styled(Box)(({theme}) => ({
    height: "120vh",
    width: "100%",
    boxSizing: "border-box",
    overflow: "hidden",
    position: "relative",
    paddingBottom: "100px",
    [theme.breakpoints.down("md")]: {
        height: "fit-content"
    }
}));
const BannerContent = styled(Box)(({theme}) => ({
    maxWidth: 520,
    color: "#fff",
    padding: "200px 0px",
    [theme.breakpoints.down("md")]: {
        maxWidth: 700,
        padding: "100px 0"
    }
}));
const ImageWrapper = styled(Box)(() => ({
    maxWidth: "100%"
}));
const Image = styled("img")(() => ({
    width: "100%"
}));
const BannerTitle = styled(Typography)(({theme}) => ({
    ...theme.typography.h2,
    color: "#fff",
    [theme.breakpoints.down("lg")]: {
        fontSize: 32
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: 24
    }
}));
const BannerSubtitle = styled(Typography)(({theme}) => ({
    ...theme.typography.h6,
    color: "#fff"
}));
const BannerParagraph = styled(Typography)(({theme}) => ({
    ...theme.typography.subtitle2,
    padding: "20px 0 30px",
    color: theme.palette.primary.lighter
}));
const SubscribeSection = styled("form")(({theme}) => ({
    display: "flex",
    zIndex: 3,
    [theme.breakpoints.down("md")]: {
        flexDirection: "column"
    },
    [theme.breakpoints.down("sm")]: {
        justifyContent: "center"
    }
}));
const TextField = styled("input")(({theme}) => ({
    outline: "none",
    padding: "0 25px",
    border: "none",
    borderRadius: 5,
    height: 55,
    lineHeight: 55,
    width: "400px",
    background: "rgba(255,255,255,0.25)",
    transition: ".3s",
    fontSize: 16,
    color: "#fff",
    "&::placeholder": {
        color: "#fff",
        fontFamily: theme.typography.fontFamily
    },
    "&:hover": {
        background: "#fff",
        color: theme.palette.grey[500],
        "&::placeholder": {
            color: theme.palette.grey[500]
        }
    },
    [theme.breakpoints.down("lg")]: {
        width: 350
    },
    [theme.breakpoints.down("md")]: {
        width: "100%",
        marginBottom: "10px"
    }
}));
const BannerButton = styled(CustomButton)(({theme}) => ({
    marginLeft: 20,
    background: theme.palette.primary.darker,
    height: 55,
    color: "#fff",
    [theme.breakpoints.down("lg")]: {
        padding: "25px 15px"
    },
    [theme.breakpoints.down("md")]: {
        margin: 0,
        padding: 0,
        width: "100%"
    }
}));
