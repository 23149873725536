import {styled} from "@mui/material/styles";
import {Box, Button, Typography} from "@mui/material";

export const RootStyle = styled(Box)(({theme}) => ({
    width: "100%",
    padding: "70px 0",
    position: "relative",
    boxSizing: "border-box",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
        padding: "50px 0"
    }
}));

export const Title = styled(Typography)(({theme}) => ({
    ...theme.typography.h2,
    color: theme.palette.primary.main,
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
        fontSize: 32
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: 24
    }
}));

export const SideTitle = styled(Typography)(({theme}) => ({
    ...theme.typography.h2,
    color: theme.palette.primary.main,
    paddingBottom: 20,
    [theme.breakpoints.down("lg")]: {
        fontSize: 32
    },
    [theme.breakpoints.down("md")]: {
        color: theme.palette.primary.main,
        ...theme.typography.h2,
        textAlign: "center"
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: 24
    }
}));

export const SubTitle = styled(Typography)(({theme}) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    paddingTop: 20,
    textAlign: "center"
}));
export const SubHeader = styled(Typography)(({theme}) => ({
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    paddingTop: 5,
    textAlign: "center"
}));

export const SideSubTitle = styled(Typography)(({theme}) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("md")]: {
        textAlign: "center",
        paddingBottom: 20
    },
    [theme.breakpoints.down("sm")]: {
        paddingBottom: 40
    }
}));
export const CenterWrapper = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center"
}));
export const Body = styled(Box)(({theme}) => ({
    ...theme.typography.body1
}));
export const CentreWrapper = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
}));
export const AlignCenterWrapper = styled(Box)(() => ({
    display: "flex",
    alignItems: "center"
}));

export const ColumnWrapper = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column"
}));

export const InsetButton = styled(Button)(({theme}) => ({
    width: "170px",
    height: 42,
    borderRadius: 8,
    background: theme.palette.primary.darker,
    color: "#fff",
    boxShadow: `inset 0 0 0 0 ${theme.palette.primary.dark}`,
    transition: "ease-out .5s",
    "&:hover": {
        boxShadow: `inset 300px 0 0 0 ${theme.palette.primary.dark}`,
        transition: "ease-in .5s"
    }
}));
export const OverLayImage = styled(Box)(() => ({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0,0,0,0.4)",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "opacity 0.3s",
    opacity: 0,
    "&:hover": {
        opacity: 1
    }
}));
export const PageLink = styled("a")(({theme}) => ({
    textDecoration: "none",
    [theme.breakpoints.down("md")]: {
        width: "100%",
        display: "flex",
        justifyContent: "center"
    }
}));
