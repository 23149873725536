import axios from "axios";

const crypto = [
    "Bitcoin",
    "Ethereum",
    "Litecoin",
    "Bitcoin Cash",
    "Dash",
    "BNB",
    "Tether",
    "Binance USD",
    "Shiba Inu",
    "Chainlink",
    "OMG Network"
];
const getCryptoData = async (url) => {
    const {data} = await axios.get(url);
    const newData = data.filter((object) => crypto.includes(object.name));
    return newData;
};
export default getCryptoData;
