export const aboutData = [
    {
        id: 1,
        header: "Designed for everyone",
        subheader:
            "Our ecosystem is designed to fit and accommodate all users from around the world."
    },
    {
        id: 2,
        header: "All the tools you want",
        subheader:
            "IwcExchange is designed and well equipped with easy to use trading tools and features."
    },
    {
        id: 3,
        header: "Quick to set up",
        subheader:
            "Set up your account in a few steps, join us today and get started!"
    }
];
