import React from "react";
import {Box, Container, Grid, Typography} from "@mui/material";
import {ArrowDropDown, ArrowDropUp} from "@mui/icons-material";
import {CustomButton} from "../themeButton";
import {CenterWrapper, RootStyle, SubTitle, Title} from "../components";
import {styled} from "@mui/material/styles";
import {Icon} from "@iconify/react";
import {keyframes} from "@emotion/react";

const Explore = ({cryptoArray}) => {
    const arr = cryptoArray.concat(cryptoArray);
    const CryptoArray = arr.concat(arr);
    return (
        <ExploreStyle>
            <Container maxWidth="lg">
                <Grid container direction="column">
                    <Grid item sx={{width: "100%"}}>
                        <CenterWrapper>
                            <CryptoBox>
                                <CryptoWrapper>
                                    {CryptoArray.map((listItem) => {
                                        const str =
                                            listItem.price_change_percentage_24h.toString();
                                        return (
                                            <CryptoList key={listItem.id}>
                                                <CryptoItemWrapper>
                                                    <CryptoImage
                                                        src={listItem.image}
                                                        alt="crypto_img"
                                                    />
                                                    <CryptoAcr>
                                                        {listItem.symbol.toUpperCase()}
                                                    </CryptoAcr>
                                                    :&nbsp;
                                                    {str.includes("-") ? (
                                                        <PriceRed>
                                                            {"$" +
                                                                listItem.current_price}
                                                            (
                                                            {Math.round(
                                                                (listItem.price_change_percentage_24h +
                                                                    Number.EPSILON) *
                                                                    100
                                                            ) /
                                                                100 +
                                                                "%"}
                                                            )
                                                        </PriceRed>
                                                    ) : (
                                                        <PriceGreen>
                                                            {"$" +
                                                                listItem.current_price}
                                                            (
                                                            {Math.round(
                                                                (listItem.price_change_percentage_24h +
                                                                    Number.EPSILON) *
                                                                    100
                                                            ) /
                                                                100 +
                                                                "%"}
                                                            )
                                                        </PriceGreen>
                                                    )}
                                                    <CryptoItemIcon>
                                                        {str.includes("-") ? (
                                                            <ArrowDown />
                                                        ) : (
                                                            <ArrowUp />
                                                        )}
                                                    </CryptoItemIcon>
                                                </CryptoItemWrapper>
                                                <CenterWrapper>
                                                    <CryptoName>
                                                        {listItem.name}
                                                    </CryptoName>
                                                </CenterWrapper>
                                            </CryptoList>
                                        );
                                    })}
                                </CryptoWrapper>
                            </CryptoBox>
                        </CenterWrapper>
                    </Grid>
                    <Grid item sx={{width: "100%"}}>
                        <ExploreCrypto>
                            <Title>
                                Trade, Buy and Sell Popular Cryptocurrencies on
                                IwcExchange.
                            </Title>
                            <SubTitle>
                                Buy through our partners and receive assets
                                directly into your IWC personal Wallet
                            </SubTitle>
                            <CryptoItems>
                                <CryptoIcon icon="akar-icons:bitcoin-fill" />
                                <CryptoIconCenter icon="cib:ethereum" />
                                <CryptoIcon icon="simple-icons:litecoin" />
                            </CryptoItems>
                            <CenterWrapper sx={{marginTop: "40px"}}>
                                <PageLink href="/auth/login">
                                    <ExploreButton>Purchase</ExploreButton>
                                </PageLink>
                            </CenterWrapper>
                        </ExploreCrypto>
                    </Grid>
                </Grid>
            </Container>
        </ExploreStyle>
    );
};

export default Explore;

const ExploreStyle = styled(RootStyle)(({theme}) => ({
    padding: "0px 0px 70px",
    [theme.breakpoints.down("md")]: {
        padding: "50px 0"
    }
}));
const CryptoBox = styled(Box)(({theme}) => ({
    width: "100%",
    height: "80px",
    boxShadow: theme.customShadows.primary,
    display: "flex",
    alignItems: "center",
    borderRadius: 7,
    marginBottom: 70,
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
        padding: "0 30px"
    }
}));
const CryptoWrapper = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    width: "max-content",
    animation: `${marquee} 160s linear infinite`,
    "&:hover": {
        animationPlayState: "paused",
        cursor: "pointer"
    }
}));
const CryptoList = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    "&:not(:first-of-type)": {
        marginLeft: 70
    },
    [theme.breakpoints.down("md")]: {
        "&:not(:first-of-type)": {
            marginLeft: 25
        }
    }
}));
const CryptoImage = styled("img")(() => ({
    width: 20
}));
const CryptoAcr = styled(Typography)(({theme}) => ({
    ...theme.typography.body1,
    paddingLeft: 10,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("md")]: {
        fontSize: 14
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: 12
    }
}));
const PriceRed = styled(Typography)(({theme}) => ({
    ...theme.typography.body1,
    color: "red",
    [theme.breakpoints.down("md")]: {
        fontSize: 14
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: 12
    }
}));
const PriceGreen = styled(Typography)(({theme}) => ({
    ...theme.typography.body1,
    color: "#098551",
    [theme.breakpoints.down("md")]: {
        fontSize: 14
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: 12
    }
}));
const CryptoItemIcon = styled(Box)(() => ({
    padding: 0
}));

const CryptoName = styled(Typography)(({theme}) => ({
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("md")]: {
        fontSize: 14
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: 12
    }
}));
export const CryptoItemWrapper = styled(Box)(() => ({
    display: "flex",
    alignItems: "center"
}));
export const ArrowDown = styled(ArrowDropDown)(() => ({
    color: "red"
}));
export const ArrowUp = styled(ArrowDropUp)(() => ({
    color: "#098551"
}));
export const ExploreCrypto = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column"
}));
export const CryptoIcon = styled(Icon)(({theme}) => ({
    color: theme.palette.primary.main,
    fontSize: 60
}));
export const CryptoIconCenter = styled(Icon)(({theme}) => ({
    background: theme.palette.primary.main,
    color: theme.palette.background.default,
    borderRadius: "50%",
    fontSize: 100,
    padding: 20,
    margin: "0 10px"
}));
export const CryptoItems = styled(Box)(() => ({
    display: "flex",
    width: "100%",
    justifyContent: "center",
    marginTop: "50px",
    alignItems: "center"
}));
export const ExploreButton = styled(CustomButton)(({theme}) => ({
    width: "30%",
    [theme.breakpoints.down("sm")]: {
        fontSize: 10,
        width: "40%"
    }
}));
export const PageLink = styled("a")(() => ({
    textDecoration: "none",
    width: "100%",
    display: "flex",
    justifyContent: "center"
}));
const marquee = keyframes`
  0% {
    transform: translate( 100px, 0);
    
  }
  100% {
    transform: translate(-100%, 0);
  }
`;
