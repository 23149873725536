import React from "react";
import {
    CentreWrapper,
    ColumnWrapper,
    RootStyle,
    SubTitle,
    Title,
    SideTitle
} from "../components";
import {Box, Container, Tab, Tabs} from "@mui/material";
import Tabcontent from "./tabcontent";
import PlaceOrder from "./components/placeOrder";
import PaySeller from "./components/paySeller";
import GetCrypto from "./components/getCrypto";
import ConfirmPayment from "./components/confirmPayment";
import ReleaseCrypto from "./components/releaseCrypto";
import {useTheme} from "@mui/material/styles";

const P2p = () => {
    const [value, setValue] = React.useState(0);
    const theme = useTheme();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <RootStyle>
            <Container maxWidth="lg">
                <ColumnWrapper>
                    <CentreWrapper>
                        <Title>Cryptitan&apos;s Peer-to-Peer Exchange</Title>
                        <SubTitle sx={{width: {xs: "100%", md: "85%"}}}>
                            Cryptitan is an easy to use platform that allows you
                            buy and sell cryptocurrencies directly with other
                            users. Transactions are done exclusively between the
                            buyer and the seller, with minimal to zero
                            involvement from any third-party intermediary.
                        </SubTitle>
                    </CentreWrapper>
                    <ColumnWrapper sx={{marginTop: {xs: "20px", md: "50px"}}}>
                        <SideTitle
                            sx={{...theme.typography.h4, fontWeight: 600}}>
                            How P2P Works
                        </SideTitle>
                        <Box sx={{width: "100%"}}>
                            <Box>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="basic tabs example">
                                    <Tab label="Buy" {...a11yProps(0)} />
                                    <Tab label="Sell" {...a11yProps(1)} />
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                <Tabcontent data={buyTrade} />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <Tabcontent data={sellTrade} />
                            </TabPanel>
                        </Box>
                    </ColumnWrapper>
                </ColumnWrapper>
            </Container>
        </RootStyle>
    );
};
function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && <Box sx={{p: 3}}>{children}</Box>}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`
    };
}
const buyTrade = [
    {
        icon: <PlaceOrder />,
        title: "Place an Order",
        subtitle:
            "Once you place a P2P order, the crypto asset will be escrowed by CryptitanP2P."
    },
    {
        icon: <PaySeller />,
        title: "Pay the Seller",
        subtitle:
            "Send money to the seller via the suggested payment methods. Complete the fiat transaction and notify seller on Cryptitan P2P."
    },
    {
        icon: <GetCrypto />,
        title: "Get your Crypto",
        subtitle:
            "Once the seller confirms receipt of money, the escrowed crypto will be released to you."
    }
];
const sellTrade = [
    {
        icon: <PlaceOrder />,
        title: "Place an Order",
        subtitle:
            "After you place an order, your crypto will be escrowed by Cryptitan P2P."
    },
    {
        icon: <ConfirmPayment />,
        title: "Confirm the Payment",
        subtitle:
            "Check the transaction record in the given payment account, and make sure you receive the money sent by the buyer."
    },
    {
        icon: <ReleaseCrypto />,
        title: "Release Crypto",
        subtitle:
            "Once you confirm the receipt of money, release crypto to the buyer on Cryptitan P2P."
    }
];
export default P2p;
