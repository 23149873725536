export const faqItems = [
    {
        id: 1,
        question: "What is (IWC)?",
        answer:
            "Incredible Wealth Coin is a digital currency (crypto currency); A one-stop ecosystem that is based on the Binance Smartchain Network.\n" +
            "IWC provides its user's and holders with a different range of advanced global digital financial service network for their everyday life transactions. We’re aimed to creating incredible wealth opportunities for everyone around the globe utilizing the power of blockchain technology.\n",
        panel: "panel1"
    },
    {
        id: 2,
        question: "How do I register?",
        answer: "Go to don't have an account click on the get started button and follow the instructions step by step in order to successfully register.",
        panel: "panel2"
    },
    {
        id: 3,
        question: "Can I Store other Crypto currencies in to my IWC account?",
        answer: "Yes! You can deposit and store other crypto currencies in your account such as bitcoin, Binance, ethereum etc, we highly recommend IWC wallet because it supports other popular crypto currencies very secure and highly protected.",
        panel: "panel3"
    },
    {
        id: 4,
        question: "How can I buy IWC?",
        answer: "You can make your purchase by simply using other crypto currencies such as bitcoin, Binance, ethereum, etc, you can also buy directly using your credit or debit card via our multiple payment gateways provided.",
        panel: "panel4"
    }
];

export const depositItems = [
    {
        id: 1,
        question: "How do I deposit into my account?",
        answer: "You can make use of direct bank transfer if available for your region or the payment gateway available in the system.",
        panel: "panel1"
    },
    {
        id: 2,
        question: "I made multiple deposit but its showing only once",
        answer: "If changes do not reflect after a while, please contact our support for assistance",
        panel: "panel2"
    },
    {
        id: 3,
        question: "Deposit not showing in my wallet?",
        answer: "If changes do not reflect after a while, please contact our support for assistance",
        panel: "panel3"
    },
    {
        id: 4,
        question: "I didn't use my own bank account",
        answer: "You can change your desired bank account at any time!",
        panel: "panel4"
    },
    {
        id: 5,
        question: "I need help with payment methods",
        answer: "For assistance please make use of the Get support button in the system or through the contact form below! ",
        panel: "panel5"
    }
];

export const accountItems = [
    {
        id: 1,
        question: "How do I set up my account?",
        answer: "You get started with by registering an account with us and completing your kyc verification",
        panel: "panel1"
    },
    {
        id: 2,
        question: "How do I close down my account?",
        answer: "Contact our support as regards getting your account deleted",
        panel: "panel2"
    },
    {
        id: 3,
        question: "My account is locked",
        answer: "If your account is disabled, we have noticed strange activities on your account. Contact our support on how to resolve this issue",
        panel: "panel3"
    },
    {
        id: 4,
        question: "How can I change verified personal details?",
        answer: "Contact out support to change verified documents",
        panel: "panel4"
    },
    {
        id: 5,
        question: "How can i change my preferred language?",
        answer: "You'll find a selection panel to change to available locales on the home page!",
        panel: "panel5"
    }
];

export const securityItems = [
    {
        id: 1,
        question: "Is Cryptitan regulated ?",
        answer: "Yes, cryptitan is regulated!",
        panel: "panel1"
    },
    {
        id: 2,
        question: "How secure is my transactions?",
        answer: "We have taken a lot of security precautions to ensure that our system is protected from all sorts of security threats from within and outside the system",
        panel: "panel2"
    },
    {
        id: 3,
        question: "How can I reset my account password?",
        answer: "You can reset your password in the security tab under Account settings!",
        panel: "panel3"
    },
    {
        id: 4,
        question: "How can I report a fraudulent transaction?",
        answer: "Contact our support using the Get support button in the system or the contact form below to report a fraudulent activity",
        panel: "panel4"
    }
];
