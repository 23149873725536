import React from "react";
import {
    Box,
    Card,
    CardContent,
    Container,
    Grid,
    Typography
} from "@mui/material";
import wallet from "../../../assets/images/wallet.svg";
import exchange from "../../../assets/images/exhange.svg";
import giftcard from "../../../assets/images/social.svg";
import Fade from "react-reveal";
import {
    CenterWrapper,
    ColumnWrapper,
    RootStyle,
    SideSubTitle,
    SideTitle
} from "../components";
import {styled, useTheme} from "@mui/material/styles";

const Features = () => {
    const theme = useTheme();
    return (
        <RootStyle id="features">
            <Container maxWidth="lg">
                <Grid container alignItems="center">
                    <Grid item xs={12} md={5}>
                        <Fade left>
                            <ColumnWrapper sx={{marginRight: "5%"}}>
                                <SideTitle
                                    sx={{
                                        color: theme.palette.text.primary,
                                        fontWeight: "lighter"
                                    }}>
                                    Our Unique{" "}
                                    <FeatureSpan>Features</FeatureSpan>
                                </SideTitle>
                                <SideSubTitle>
                                    We have highlighted the most important key
                                    features such as crypto currencies deposit
                                    and withdrawal, P2P trading, Spot trading,
                                    direct Fiat deposit and withdrawal through
                                    credit/debit cards, gift cards and MTN
                                    mobile money among others. Our development
                                    team is working constantly to make more
                                    Amazing features available
                                </SideSubTitle>
                            </ColumnWrapper>
                        </Fade>
                    </Grid>
                    <Grid item container xs={12} md={7} alignItems="center">
                        <Grid item xs={12} sm={6}>
                            <Fade bottom>
                                <FeatureCard>
                                    <CardContent>
                                        <CenterWrapper
                                            sx={{paddingTop: "20px"}}>
                                            <CardBackground>
                                                <CardIcon
                                                    src={wallet}
                                                    alt="#"
                                                />
                                            </CardBackground>
                                        </CenterWrapper>
                                        <CardHeader>
                                            Self hosted Wallets
                                        </CardHeader>
                                        <CardSubHeader>
                                            Through Binance Smartchain network,
                                            IwcExchange provides wallet
                                            functionalities to registered users
                                            with unique wallet Address.
                                        </CardSubHeader>
                                    </CardContent>
                                </FeatureCard>
                            </Fade>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Fade bottom>
                                <FeatureCard sx={{marginTop: "15px"}}>
                                    <CardContent>
                                        <CenterWrapper
                                            sx={{paddingTop: "20px"}}>
                                            <CardBackground>
                                                <CardIcon
                                                    src={exchange}
                                                    alt="#"
                                                />
                                            </CardBackground>
                                        </CenterWrapper>
                                        <CardHeader>
                                            Instant Trade and Exchange
                                        </CardHeader>
                                        <CardSubHeader>
                                            IwcExchange supports buying and
                                            selling crypto currencies with
                                            direct bank transfer or payment
                                            gateways without additional Exchange
                                            setting fees.
                                        </CardSubHeader>
                                    </CardContent>
                                </FeatureCard>
                            </Fade>
                            <Fade bottom>
                                <FeatureCard sx={{marginTop: "15px"}}>
                                    <CardContent>
                                        <CenterWrapper
                                            sx={{paddingTop: "20px"}}>
                                            <CardBackground>
                                                <CardIcon
                                                    src={giftcard}
                                                    alt="#"
                                                />
                                            </CardBackground>
                                        </CenterWrapper>
                                        <CardHeader>
                                            Social Enhancement
                                        </CardHeader>
                                        <CardSubHeader>
                                            Our unique system allows users to
                                            share contents that matters to them
                                            with the world. Users also get to
                                            meet new people and build new
                                            relationships like Facebook,
                                            Twitter, Instagram, etc...
                                        </CardSubHeader>
                                    </CardContent>
                                </FeatureCard>
                            </Fade>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </RootStyle>
    );
};

export default Features;
const FeatureSpan = styled("span")(({theme}) => ({
    color: theme.palette.primary.main,
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
        fontWeight: 900
    }
}));
const FeatureCard = styled(Card)(({theme}) => ({
    marginLeft: "15px",
    boxShadow: theme.customShadows.primary,
    [theme.breakpoints.down("sm")]: {
        margin: 0,
        marginBottom: 20,
        width: "100%"
    }
}));
const CardHeader = styled(Typography)(({theme}) => ({
    ...theme.typography.h6,
    color: theme.palette.primary.main,
    fontSize: 18,
    textAlign: "center",
    paddingTop: 20
}));
const CardSubHeader = styled(Typography)(({theme}) => ({
    ...theme.typography.body2,
    color: theme.palette.grey[500],
    textAlign: "center",
    padding: "20px 0"
}));
const CardIcon = styled("img")(() => ({
    width: 130,
    height: 130,
    padding: 30
}));
const CardBackground = styled(Box)(({theme}) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.primary.light,
    borderRadius: "50%",
    border: `10px solid ${theme.palette.primary.light}`,
    borderTopColor: theme.palette.primary.dark,
    borderRightColor: theme.palette.primary.lighter,
    borderLeftColor: theme.palette.primary.lighter,
    borderBottomColor: theme.palette.primary.dark
}));
