import {styled} from "@mui/material/styles";
import Button from "@mui/material/Button";

export const CustomButton = styled(Button)(({theme}) => ({
    position: "relative",
    height: 48,
    padding: "8px 22px",
    letterSpacing: 1.1,
    border: "none",
    cursor: "pointer",
    borderRadius: 8,
    color: "#fff",
    background: theme.palette.primary.main,
    boxShadow: theme.customShadows.primary,
    transition: "ease-out .5s",
    zIndex: 1,
    "&:hover": {
        color: "#fff"
    },
    "&::before": {
        content: "''",
        position: "absolute",
        left: "50%",
        right: "50%",
        top: 0,
        bottom: 0,
        transition: "all .5s ease",
        borderRadius: 5,
        opacity: 0,
        background: theme.palette.primary.dark
    },
    "&:hover::before": {
        transition: "all .5s ease",
        left: 0,
        right: 0,
        opacity: 1,
        zIndex: -1
    }
}));
