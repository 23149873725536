import React from "react";
import {Drawer, List, ListItem, ListItemIcon, Box} from "@mui/material";
import {navItems} from "../Navbar/data";
import {Link as ScrollLink} from "react-scroll";
import {Close} from "@mui/icons-material";
import {styled, useTheme} from "@mui/material/styles";
import {CenterWrapper, InsetButton, PageLink} from "../components";

const Index = ({open, toggle}) => {
    const theme = useTheme();
    const drawerWidth = 400;
    return (
        <Drawer
            anchor="left"
            open={open}
            onClose={toggle}
            sx={{
                "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                    background: theme.palette.background.default
                }
            }}>
            <List>
                <ListItem
                    onClick={toggle}
                    sx={{justifyContent: "flex-end", cursor: "pointer"}}>
                    <ListItemIcon sx={{minWidth: "auto"}}>
                        <Close
                            sx={{
                                fontSize: "30px",
                                color: theme.palette.primary.main
                            }}
                        />
                    </ListItemIcon>
                </ListItem>
                <Box sx={{paddingTop: "50px"}}>
                    {navItems.map((navItem) => (
                        <ListItem
                            key={navItem.id}
                            sx={{flexDirection: "column"}}>
                            <SideLink
                                to={navItem.idnm}
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact="true"
                                data-name={navItem.name}
                                offset={-80}
                                onClick={toggle}>
                                {navItem.name}
                            </SideLink>
                        </ListItem>
                    ))}
                </Box>
            </List>
            <CenterWrapper sx={{paddingTop: "50px"}}>
                <PageLink href="/auth/register">
                    <InsetButton>Get Started</InsetButton>
                </PageLink>
            </CenterWrapper>
        </Drawer>
    );
};

export default Index;
const SideLink = styled(ScrollLink)(({theme}) => ({
    position: "relative",
    padding: "25px 0",
    color: theme.palette.primary.main,
    cursor: "pointer",
    "&::before": {
        content: "attr(data-name)",
        position: "absolute",
        width: "0px",
        transition: "all .5s ease-in",
        overflow: "hidden",
        color: theme.palette.primary.light
    },
    "&:hover::before": {
        width: "100%",
        transition: "all .5s ease-out"
    }
}));
