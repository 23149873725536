import React from "react";
import {IconButton, List, Box, ListItem, Toolbar} from "@mui/material";
import {Link as ScrollLink, animateScroll as scroll} from "react-scroll";
import {navItems} from "./data";
import {Menu} from "@mui/icons-material";
import {styled} from "@mui/material/styles";
import {InsetButton, PageLink} from "../components";
import LogoSvg from "../../../assets/images/IWC Exchange.png";
const Navbar = ({scrollNav, toggle}) => {
    const toggleHome = () => {
        scroll.scrollToTop();
    };
    return (
        <NavBar disableGutters scrollNav={scrollNav}>
            <LogoContainer onClick={toggleHome}>
                <LogoImg src={LogoSvg} alt="logo" />
            </LogoContainer>
            <Box sx={{flexGrow: 0.5}}></Box>
            <Box sx={{display: {xs: "none", md: "block"}}}>
                <Box sx={{display: "flex", alignItems: "center"}}>
                    <NavList>
                        {navItems.map((navItem) => (
                            <ListItem key={navItem.id}>
                                <NavLink
                                    scrollNav={scrollNav}
                                    to={navItem.idnm}
                                    smooth={true}
                                    duration={500}
                                    spy={true}
                                    exact="true"
                                    offset={-100}
                                    data-name={navItem.name}>
                                    {navItem.name}
                                </NavLink>
                            </ListItem>
                        ))}
                    </NavList>
                    <ButtonWrapper>
                        <PageLink href="/auth/register">
                            <NavButton scrollNav={scrollNav}>
                                Get Started
                            </NavButton>
                        </PageLink>
                    </ButtonWrapper>
                </Box>
            </Box>
            <Box sx={{display: {xs: "block", md: "none"}}}>
                <Box sx={{display: "flex", alignItems: "center"}}>
                    <NavIconButton aria-label="Menu" onClick={toggle}>
                        <NavIcon scrollNav={scrollNav} />
                    </NavIconButton>
                </Box>
            </Box>
        </NavBar>
    );
};

export default Navbar;

const NavBar = styled(Toolbar)(({theme, scrollNav}) => ({
    height: "80px",
    width: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    background: scrollNav ? theme.palette.background.paper : "transparent",
    boxShadow: scrollNav ? theme.shadows[3] : "none",
    zIndex: 999
}));
const LogoContainer = styled(Box)(({theme}) => ({
    display: "flex",
    alignItems: "center",
    position: "absolute",
    left: "10%",
    cursor: "pointer",
    [theme.breakpoints.down("lg")]: {
        left: "5%"
    }
}));
const LogoImg = styled("img")(({theme, scrollNav}) => ({
    height: 70,
    width: 70
}));
const NavList = styled(List)(({theme}) => ({
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("lg")]: {
        marginLeft: "-50px"
    }
}));
const NavLink = styled(ScrollLink)(({theme, scrollNav}) => ({
    fontFamily: theme.typography.fontFamily,
    position: "relative",
    padding: "25px 0",
    color: scrollNav ? theme.palette.primary.main : "#000",
    cursor: "pointer",
    "&.active": {
        color: scrollNav ? theme.palette.primary.dark : "#000",
        fontWeight: "bold"
    },
    "&::before": {
        content: "attr(data-name)",
        position: "absolute",
        width: "0px",
        transition: "all .5s ease-out",
        overflow: "hidden",
        color: theme.palette.primary.light
    },
    "&:hover::before": {
        width: "100%",
        transition: "all .5s ease-in"
    },
    [theme.breakpoints.down("lg")]: {
        fontSize: 14
    }
}));
const NavButton = styled(InsetButton)(({theme, scrollNav}) => ({
    background: scrollNav
        ? theme.palette.primary.main
        : theme.palette.primary.darker
}));
const ButtonWrapper = styled(Box)(({theme}) => ({
    position: "absolute",
    right: "10%",
    [theme.breakpoints.down("lg")]: {
        right: "5%"
    }
}));
const NavIconButton = styled(IconButton)(() => ({
    position: "absolute",
    right: "10%"
}));
const NavIcon = styled(Menu)(({theme, scrollNav}) => ({
    fontSize: "30px",
    color: scrollNav ? theme.palette.primary.darker : "#fff"
}));
