export const typography = {
    fontFamily: "Public Sans, sans-serif",
    h1: {
        fontWeight: 900,
        fontSize: "48px",
        lineHeight: 1.1
    },
    h2: {
        fontWeight: 900,
        fontSize: "40px",
        lineHeight: 1.5
    },
    h3: {
        fontWeight: 900,
        fontSize: "32px",
        lineHeight: 1.25
    },
    h4: {
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: 1.35
    },
    h5: {
        fontWeight: 600,
        fontSize: "20px",
        lineHeight: 1.4
    },
    h6: {
        fontWeight: 600,
        fontSize: "18px",
        lineHeight: 1.45
    },
    subtitle1: {
        fontSize: "16px",
        lineHeight: 1.6
    },
    subtitle2: {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: 1.6
    },
    body1: {
        fontSize: "16px",
        lineHeight: 1.3
    },
    body2: {
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: 1.45
    }
};
