import * as React from "react";
import {styled} from "@mui/material/styles";
import {keyframes} from "@emotion/react";

function ConfirmPayment() {
    return (
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 122.88 101.13"
            xmlSpace="preserve">
            <path
                d="M86.64 45.01c3.23 0 6.1 1.56 7.89 3.96a9.8 9.8 0 017.89-3.96c5.43 0 9.83 4.4 9.83 9.83s-4.4 9.83-9.83 9.83c-3.23 0-6.1-1.56-7.89-3.96a9.8 9.8 0 01-7.89 3.96c-5.43 0-9.83-4.4-9.83-9.83s4.4-9.83 9.83-9.83zm-47.46-8.08c10.89 6.9 20.73 10.17 29.17 9.4.54 10.94-.6 20.24-3.22 27.98h51c.78 0 1.49-.32 2.01-.84.52-.52.84-1.23.84-2.01V33.99H3.91v37.47c0 .78.32 1.49.84 2.01.52.52 1.23.84 2.01.84h6.89c-2.58-7.74-3.82-17.15-3.53-28.44 9.91.53 19.64-1.62 29.06-8.94zm24.45 41.29c-4.86 11.21-13.22 18.72-24.34 22.91-10.92-3.99-19.25-11.33-24.17-22.91H6.76c-1.86 0-3.55-.76-4.77-1.98A6.702 6.702 0 010 71.47V6.76C0 4.9.76 3.21 1.98 1.98A6.776 6.776 0 016.76 0h109.37c1.86 0 3.55.76 4.77 1.98a6.753 6.753 0 011.98 4.77v64.7c0 1.86-.76 3.55-1.98 4.77a6.744 6.744 0 01-4.77 1.99h-52.5v.01zM39.2 42.91c9.05 5.74 17.23 8.45 24.24 7.81.48 9.64-.62 17.66-3.09 24.23l-.07.18c-.08.2-.15.4-.23.59l-.09.22c-.07.18-.15.36-.22.54l-.09.21-.24.54-.11.25-.21.45-.15.3c-4.11 8.48-10.84 14.16-19.66 17.48C30.6 92.54 23.89 87 19.74 78.23l-.03-.06-.01-.07-.03-.06c-.07-.16-.14-.31-.21-.47l-.03-.06-.03-.06-.03-.06-.03-.06-.03-.06-.21-.48-.03-.06-.03-.06-.03-.06c-.1-.24-.2-.49-.3-.73l-.02-.06-.02-.06c-.07-.19-.15-.37-.22-.56l-.02-.06-.02-.06-.02-.06-.02-.06-.21-.57-.02-.06c-2.26-6.44-3.36-14.35-3.11-23.98 8.26.42 16.34-1.36 24.17-7.44zM3.91 14.56h115.06v-7.8c0-.78-.32-1.49-.84-2.01-.52-.52-1.23-.84-2.01-.84H6.76c-.78 0-1.49.32-2.01.84-.52.52-.84 1.23-.84 2.01v7.8z"
                fillRule="evenodd"
                clipRule="evenodd"
            />
            <path d="M24.62 67.34l5.17-.07.39.1c1.04.6 2.03 1.29 2.95 2.07.66.56 1.3 1.17 1.9 1.84 1.86-2.99 3.83-5.73 5.92-8.25a71.54 71.54 0 017.27-7.56l.5-.19h5.65l-1.14 1.26c-3.5 3.89-6.67 7.9-9.54 12.05-2.87 4.15-5.43 8.43-7.71 12.83l-.71 1.37-.65-1.39c-1.2-2.58-2.64-4.95-4.36-7.07-1.72-2.12-3.72-4-6.05-5.6l.41-1.39z" />
        </Svg>
    );
}

export default ConfirmPayment;

export const Svg = styled("svg")(({theme}) => ({
    width: 150,
    height: 150,
    fill: theme.palette.primary.light,
    "&:hover": {
        cursor: "pointer",
        animation: `${shake} 0.82s cubic-bezier(.36,.07,.19,.97) both`,
        transform: "translate3d(0, 0, 0)",
        backfaceVisibility: "hidden",
        perspective: "1000px"
    },
    [theme.breakpoints.down("md")]: {
        width: 100,
        height: 100
    }
}));

const shake = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`;
