import React from "react";
import {
    Box,
    Button,
    Container,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    Typography
} from "@mui/material";
import {CustomButton} from "../themeButton";
import {aboutData} from "./data";
import aboutImg from "../../../assets/images/marketplace.png";
import Fade from "react-reveal";
import {Icon} from "@iconify/react";
import {
    AlignCenterWrapper,
    ColumnWrapper,
    PageLink,
    RootStyle,
    SideSubTitle,
    SideTitle
} from "../components";
import {styled} from "@mui/material/styles";
import {keyframes} from "@emotion/react";

const About = () => {
    return (
        <RootStyle id="about">
            <Container maxWidth="lg">
                <Grid container alignItems="center">
                    <Grid item xs={12} md={6}>
                        <Fade left>
                            <ImageWrapper>
                                <AboutImageAnimation />
                                <AboutImage src={aboutImg} />
                            </ImageWrapper>
                        </Fade>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Fade up>
                            <SideTitle>IwcExchange</SideTitle>
                            <SideSubTitle>
                                IwcExchange supports direct crypto to (Fiat)
                                deposit and withdrawals through our multiple
                                payment gateways provided either via
                                credit/debit card payment or through bank
                                transfer which is tradable using (P2P) with
                                supported crypto currencies in the system.
                            </SideSubTitle>
                        </Fade>
                        <Fade bottom>
                            <List>
                                {aboutData.map((aboutItem) => (
                                    <ListItem
                                        key={aboutItem.id}
                                        sx={{paddingBottom: "20px"}}>
                                        <ColumnWrapper>
                                            <AlignCenterWrapper>
                                                <ListItemIcon>
                                                    <TickIcon icon="typcn:tick" />
                                                </ListItemIcon>
                                                <ListHeader>
                                                    {aboutItem.header}
                                                </ListHeader>
                                            </AlignCenterWrapper>
                                            <ListSubHeader>
                                                {aboutItem.subheader}
                                            </ListSubHeader>
                                        </ColumnWrapper>
                                    </ListItem>
                                ))}
                            </List>
                        </Fade>
                        <Fade bottom>
                            <ButtonWrapper>
                                <PageLink href="/auth/login">
                                    <TryButton>TRY IT OUT</TryButton>
                                </PageLink>
                                <PageLink href="/auth/login">
                                    <ExploreButton>
                                        EXPLORE FEATURES
                                    </ExploreButton>
                                </PageLink>
                            </ButtonWrapper>
                        </Fade>
                    </Grid>
                </Grid>
            </Container>
        </RootStyle>
    );
};

export default About;
export const TickIcon = styled(Icon)(({theme}) => ({
    fontSize: 30,
    padding: 5,
    background: theme.palette.primary.main,
    borderRadius: "50%",
    color: "#fff"
}));
export const ListHeader = styled(Typography)(({theme}) => ({
    ...theme.typography.h5,
    color: theme.palette.primary.main,
    marginLeft: -10
}));
export const ListSubHeader = styled(Typography)(({theme}) => ({
    ...theme.typography.body2,
    color: theme.palette.grey[500],
    paddingTop: 20
}));
export const ButtonWrapper = styled(Box)(({theme}) => ({
    paddingTop: 30,
    [theme.breakpoints.down("md")]: {
        paddingTop: 10,
        display: "flex",
        justifyContent: "center"
    }
}));
export const TryButton = styled(CustomButton)(({theme}) => ({
    width: "35%",
    marginRight: 20,
    [theme.breakpoints.down("md")]: {
        width: "100%"
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: 10
    }
}));
export const ExploreButton = styled(Button)(({theme}) => ({
    height: 48,
    width: "35%",
    border: "2px solid",
    borderRadius: 8,
    [theme.breakpoints.down("md")]: {
        width: "100%"
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: 10
    }
}));

const ImageWrapper = styled(Box)(({theme}) => ({
    maxWidth: 600,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
        paddingTop: 150,
        paddingBottom: 150
    },
    [theme.breakpoints.down("sm")]: {
        paddingTop: 50,
        paddingBottom: 100
    }
}));
const AboutImage = styled("img")(({theme}) => ({
    position: "relative",
    width: "100%",
    zIndex: 2,
    marginLeft: "-20%",
    marginTop: "5%",
    [theme.breakpoints.down("md")]: {
        width: 580,
        marginLeft: 0
    },
    [theme.breakpoints.down("sm")]: {
        width: 350,
        marginTop: "-5%"
    }
}));

const AboutImageAnimation = styled(Box)(({theme}) => ({
    position: "absolute",
    height: 500,
    width: 500,
    left: "40%",
    zIndex: 1,
    top: "50%",
    "&::before, &::after": {
        position: "absolute",
        content: "''",
        border: "4px solid rgba(151, 175, 213, 0.3)",
        borderRadius: "50%",
        boxSizing: "border-box"
    },
    "&::before": {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        borderTopColor: theme.palette.primary.dark,
        borderRightColor: theme.palette.primary.dark,
        animation: `${rotate} 15s linear infinite 0s;`
    },
    "&::after": {
        top: 5,
        left: 5,
        bottom: 85,
        right: 85,
        borderTopColor: theme.palette.primary.main,
        borderBottomColor: theme.palette.primary.main,
        animation: `${rotate} 10s linear infinite 0s;`
    },
    [theme.breakpoints.down("lg")]: {
        height: 400,
        width: 400
    },
    [theme.breakpoints.down("md")]: {
        left: "50%",
        height: 500,
        width: 500
    },
    [theme.breakpoints.down("sm")]: {
        height: 320,
        width: 320,
        top: "35%"
    }
}));

const rotate = keyframes`
  from {
    transform: translate(-50%, -50%) rotate(0deg)
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg)
  }
`;
