import React, {useState} from "react";
import {
    Alert,
    Box,
    CircularProgress,
    Container,
    Grid,
    Modal,
    TextareaAutosize,
    TextField,
    Typography
} from "@mui/material";
import {styled} from "@mui/material/styles";
import {CustomButton} from "../themeButton";
import Fade from "react-reveal";
import {send} from "emailjs-com";
import {
    CenterWrapper,
    ColumnWrapper,
    RootStyle,
    SideSubTitle,
    SideTitle
} from "../components";
import {Icon} from "@iconify/react";

const Contact = () => {
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [toSend, setToSend] = useState({
        from_name: "",
        to_name: "",
        message: "",
        reply_to: ""
    });

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setIsSuccess(false);
    };
    const onSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        send("service_j1me417", "template_g2vk3on", toSend, "wISGhtMd41WdJ7bR9")
            .then((response) => {
                if (response.status >= 200 && response.status <= 299) {
                    setIsSuccess(true);
                    setIsLoading(false);
                } else {
                    setIsError(true);
                }
            })
            .catch(() => {
                setIsError(true);
            });
    };

    const handleChange = (e) => {
        setToSend({...toSend, [e.target.name]: e.target.value});
    };
    return (
        <RootStyle id="contact">
            <Container maxWidth="lg">
                <Grid container>
                    <Grid item xs={12} md={5}>
                        <Fade left>
                            <ColumnWrapper>
                                <SideTitle>Get In Touch</SideTitle>
                                <ContactSubtitle>
                                    Any question? Reach out to us and we will
                                    get back to you shortly.
                                </ContactSubtitle>
                                <Fade bottom>
                                    <ContactContent>
                                        {contactItems.map((contactItem) => (
                                            <IconWrapper
                                                key={contactItem.id}
                                                onClick={() => {
                                                    window.open(
                                                        contactItem.webUrl,
                                                        "_blank"
                                                    );
                                                }}>
                                                <ContactIcon>
                                                    <ContactIcon
                                                        icon={contactItem.icon}
                                                    />
                                                </ContactIcon>
                                                <ContactText>
                                                    {contactItem.text}
                                                </ContactText>
                                            </IconWrapper>
                                        ))}
                                    </ContactContent>
                                </Fade>
                            </ColumnWrapper>
                        </Fade>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <Fade bottom>
                            <ContactForm onSubmit={onSubmit}>
                                <CustomtextField
                                    id="outlined-basic"
                                    label="Name"
                                    variant="outlined"
                                    name="from_name"
                                    value={toSend.from_name}
                                    onChange={handleChange}
                                    required
                                />
                                <CustomtextField
                                    id="outlined"
                                    label="Email"
                                    variant="outlined"
                                    required
                                    type="Email"
                                    name="reply_to"
                                    value={toSend.reply_to}
                                    onChange={handleChange}
                                />
                                <ContactTextArea
                                    aria-label="get-in-touch"
                                    minRows={10}
                                    name="message"
                                    placeholder="Your message"
                                    value={toSend.message}
                                    onChange={handleChange}
                                />
                                <CenterWrapper>
                                    <ContactButton type="submit">
                                        Send Message{" "}
                                        {isLoading ? (
                                            <CircularProgress
                                                size={20}
                                                sx={{marginLeft: "10px"}}
                                            />
                                        ) : (
                                            <PaperPlane icon="fluent:send-28-filled" />
                                        )}
                                    </ContactButton>
                                </CenterWrapper>
                            </ContactForm>
                            <Modal
                                open={isSuccess}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description">
                                <ModalStyle>
                                    <Alert
                                        severity={isError ? "error" : "success"}
                                        sx={{
                                            height: "150px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                        {isError
                                            ? "Message failed to deliver"
                                            : "Message Sent Successfully"}
                                    </Alert>
                                </ModalStyle>
                            </Modal>
                        </Fade>
                    </Grid>
                </Grid>
            </Container>
        </RootStyle>
    );
};

export default Contact;

const IconWrapper = styled(Box)(({theme}) => ({
    display: "flex",
    marginBottom: 20,
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
        flexDirection: "column"
    }
}));
const ContactForm = styled("form")(() => ({
    display: "flex",
    flexDirection: "column"
}));
const ContactContent = styled(Box)(({theme}) => ({
    marginTop: 50,
    [theme.breakpoints.down("md")]: {
        marginTop: 0
    }
}));
const ModalStyle = styled(Box)(({theme}) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: theme.shape.borderRadiusMd,
    border: "2px solid transparent",
    boxShadow: 24,
    p: 4
}));
const ContactSubtitle = styled(SideSubTitle)(({theme}) => ({
    maxWidth: 350,
    [theme.breakpoints.down("md")]: {
        maxWidth: "100%"
    }
}));
const ContactIcon = styled(Icon)(({theme}) => ({
    background: theme.palette.primary.main,
    cursor: "pointer",
    color: "#fff",
    padding: 10,
    borderRadius: "50%",
    marginRight: 15,
    fontSize: 45,
    boxShadow: theme.shadows[2],
    [theme.breakpoints.down("md")]: {
        marginBottom: 15
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: 40
    }
}));
const CustomtextField = styled(TextField)(({theme}) => ({
    marginBottom: 20,
    boxShadow: theme.customShadows.primary,
    "& input:valid + fieldset": {
        border: `1px solid ${theme.palette.primary.main}`
    },
    "& input:invalid + fieldset": {
        border: `1px solid ${theme.palette.primary.main}`
    },
    "& input:valid:focus + fieldset": {
        borderLeftWidth: 6,
        padding: "4px !important" // override inline-style
    },
    "& label": {
        color: theme.palette.primary.light,
        fontSize: 14
    },
    "& input": {
        color: theme.palette.primary.light,
        fontSize: 14
    }
}));

const ContactText = styled(Typography)(({theme}) => ({
    ...theme.palette.body2,
    color: theme.palette.grey[500]
}));
const ContactTextArea = styled(TextareaAutosize)(({theme}) => ({
    padding: 15,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary.light,
    fontSize: 14,
    border: `1px solid ${theme.palette.primary.main}`,
    marginBottom: 20,
    background: theme.palette.background.default,
    boxShadow: theme.customShadows.primary,
    borderRadius: 7,
    "&:hover": {
        outline: "none"
    },
    "&::placeholder": {
        color: theme.palette.primary.light
    },
    "&:focus": {
        outline: "none"
    }
}));
const ContactButton = styled(CustomButton)(({theme}) => ({
    width: "40%",
    [theme.breakpoints.down("lg")]: {
        fontSize: 14
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: 10,
        width: "50%"
    }
}));
const PaperPlane = styled(Icon)(({theme}) => ({
    color: "#fff",
    paddingLeft: 10,
    fontSize: 34,
    [theme.breakpoints.down("sm")]: {
        fontSize: 30
    }
}));

const contactItems = [
    {
        id: 2,
        text: "support@iwcexchange.com",
        icon: "ant-design:mail-filled",
        webUrl: "mailto:support@iwcexchange.com"
    },
    {
        id: 3,
        text: "Join us on telegram",
        icon: "cib:telegram-plane",
        webUrl: "https://t.me/+O6CHO6i-z_g1Y2Rk"
    }
];
