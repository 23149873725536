import * as React from "react";
import {styled, useTheme} from "@mui/material/styles";
function Waves(props) {
    const theme = useTheme();
    const Background = theme.palette.background.default;
    return (
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
            {...props}>
            <path
                fill={Background}
                d="M0 224l24 5.3C48 235 96 245 144 256s96 21 144 16 96-27 144-53.3c48-26.7 96-58.7 144-64 48-5.7 96 16.3 144 32 48 16.3 96 26.3 144 0 48-26.7 96-90.7 144-96 48-5.7 96 48.3 144 58.6 48 10.7 96-21.3 144-53.3l120-80 24-16v320H0z"
            />
        </Svg>
    );
}

export default Waves;
const Svg = styled("svg")(() => ({
    position: "absolute",
    left: 0,
    bottom: -1,
    zIndex: 2
}));
