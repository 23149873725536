import React from "react";
import {Box, Grid, Typography} from "@mui/material";
import {animateScroll as scroll, Link as LinkS} from "react-scroll";
import {styled} from "@mui/material/styles";
import {Icon} from "@iconify/react";
import {AlignCenterWrapper} from "../components";

const Footer = () => {
    const toggleHome = () => {
        scroll.scrollToTop();
    };
    return (
        <FooterStyle>
            <FooterGrid container direction="column">
                <Grid item>
                    <FooterLinksContainer>
                        <FooterLinksWrapper>
                            <FooterLinksItem>
                                <FooterLinksTitle>
                                    Useful Links
                                </FooterLinksTitle>
                                <FooterLink href="/auth/login">
                                    <AlignCenterWrapper>
                                        <LinkIcon icon="bi:currency-exchange" />
                                        Exchange
                                    </AlignCenterWrapper>
                                </FooterLink>
                                <FooterLink href="/auth/login">
                                    <AlignCenterWrapper>
                                        <LinkIcon icon="bi:rocket-takeoff-fill" />
                                        Stake Crypto
                                    </AlignCenterWrapper>
                                </FooterLink>
                            </FooterLinksItem>
                        </FooterLinksWrapper>
                        <FooterLinksWrapper>
                            <FooterLinksItem>
                                <FooterLinksTitle>Company</FooterLinksTitle>
                                <FooterLinks
                                    to="about"
                                    smooth={true}
                                    duration={500}
                                    spy={true}
                                    exact="true"
                                    offset={-50}>
                                    About us
                                </FooterLinks>
                                <FooterLinks
                                    to="features"
                                    smooth={true}
                                    duration={500}
                                    spy={true}
                                    exact="true"
                                    offset={-50}>
                                    Why IWC Exchange?
                                </FooterLinks>
                            </FooterLinksItem>
                        </FooterLinksWrapper>
                    </FooterLinksContainer>
                </Grid>
                <Grid item sx={{width: "100%"}}>
                    <FooterSocialIconsWrapper>
                        <FooterLogo to="/" onClick={toggleHome}>
                            <LogoWrapper>IWC Exchange</LogoWrapper>
                        </FooterLogo>
                        <WebsiteRights>
                            IWC Exchange&#169; {new Date().getFullYear()} All
                            rights reserved.
                        </WebsiteRights>
                    </FooterSocialIconsWrapper>
                </Grid>
            </FooterGrid>
        </FooterStyle>
    );
};
export default Footer;

const FooterStyle = styled(Box)(({theme}) => ({
    width: "100%",
    height: "fit-content",
    boxSizing: "border-box",
    overflow: "hidden",
    position: "relative",
    background: theme.palette.primary.main,
    paddingBottom: 0,
    [theme.breakpoints.down("md")]: {
        marginTop: 50
    }
}));
const FooterGrid = styled(Grid)(({theme}) => ({
    width: "100%",
    padding: "48px 24px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 1200,
    margin: "0 auto",
    position: "relative",
    zIndex: 2,
    [theme.breakpoints.down("md")]: {
        padding: "10px 10px 24px"
    }
}));
const FooterLinksContainer = styled(Box)(({theme}) => ({
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
        paddingTop: 32
    }
}));
const FooterLinksWrapper = styled(Box)(({theme}) => ({
    display: "flex",
    [theme.breakpoints.down("md")]: {
        flexDirection: "column"
    }
}));
const FooterLinksItem = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    margin: 16,
    textAlign: "left",
    width: 160,
    boxSizing: "border-box",
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
        margin: 0,
        padding: 10,
        width: "100%"
    }
}));
const FooterLinksTitle = styled(Typography)(({theme}) => ({
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 16,
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
        fontSize: 16
    }
}));
const FooterLink = styled("a")(({theme}) => ({
    fontFamily: theme.typography.fontFamily,
    color: "#fff",
    textDecoration: "none",
    marginBottom: "0.5rem",
    fontSize: 16,
    "&:hover": {
        color: theme.palette.primary.light,
        transition: "0.3s ease-out",
        cursor: "pointer"
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: 14
    }
}));
const FooterLinks = styled(LinkS)(({theme}) => ({
    fontFamily: theme.typography.fontFamily,
    color: "#fff",
    textDecoration: "none",
    marginBottom: "0.5rem",
    fontSize: 16,
    "&:hover": {
        color: theme.palette.primary.light,
        transition: "0.3s ease-out",
        cursor: "pointer"
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: 14
    }
}));
const LinkIcon = styled(Icon)(() => ({
    fontSize: 18,
    color: "#fff",
    marginRight: 5
}));
const LogoWrapper = styled(Box)(() => ({
    width: "100%"
}));
const FooterSocialIconsWrapper = styled(Box)(({theme}) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    margin: "40px auto 0",
    [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        alignItems: "center"
    }
}));
const FooterLogo = styled(LinkS)(({theme}) => ({
    color: "#fff",
    fontFamily: theme.typography.fontFamily,
    justifySelf: "start",
    cursor: "pointer",
    textDecoration: "none",
    fontSize: "1.5rem",
    display: "flex",
    alignItems: "center",
    marginBottom: 16,
    fontWeight: "bold"
}));
const WebsiteRights = styled(Typography)(({theme}) => ({
    color: "#fff",
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
        fontSize: 14,
        textAlign: "center"
    }
}));
