import React, {useEffect, useState} from "react";
import {
    About,
    Banner,
    Explore,
    Features,
    Navbar,
    Sidebar,
    Info,
    FAQ,
    Contact,
    Footer,
    Services
} from "./components";
import {useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import ThemeConfig from "../theme";
import getCryptoData from "../axios";

const Landing = () => {
    const url =
        "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=250&sparkline=false";
    const [scrollNav, setScrollNav] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [cryptoData, setCryptoData] = useState([]);
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up("md"), {
        defaultMatches: true
    });
    const open = isMd ? false : isOpen;
    const changeNav = () => {
        if (window.scrollY >= 60) {
            setScrollNav(true);
        } else {
            setScrollNav(false);
        }
    };
    const toggle = () => {
        setIsOpen(!isOpen);
    };
    useEffect(() => {
        window.addEventListener("scroll", changeNav);
        getCryptoData(url).then((newData) => {
            setCryptoData(newData);
        });
    }, []);

    return (
        <ThemeConfig>
            <Navbar scrollNav={scrollNav} toggle={toggle} />
            <Sidebar open={open} toggle={toggle} />
            <Banner />
            <Explore cryptoArray={cryptoData} />
            <About />
            <Features />
            <Services />
            <Info />
            <FAQ />
            <Contact />
            <Footer />
        </ThemeConfig>
    );
};

export default Landing;
