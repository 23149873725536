import React from "react";
import {Card, Container, Grid, Typography} from "@mui/material";
import Fade from "react-reveal";
import {
    CenterWrapper,
    ColumnWrapper,
    RootStyle,
    SubTitle,
    Title
} from "../components";
import {styled} from "@mui/material/styles";
import EasySvg from "./component/easy";
import SecureSvg from "./component/secure";
import RocketSvg from "./component/fast";

const Services = () => {
    return (
        <RootStyle>
            <Container maxWidth="lg">
                <Grid container direction="column">
                    <Grid item>
                        <Fade up>
                            <ColumnWrapper>
                                <Title>Why Choose Us?</Title>
                                <SubTitle>
                                    The IWC Exchange Team is working tirelessly
                                    to ensure you have wonderful experience
                                    while exploring the ecosystem.
                                </SubTitle>
                            </ColumnWrapper>
                        </Fade>
                    </Grid>
                    <Grid item>
                        <Fade bottom>
                            <ServiceCard>
                                <ServiceWrapper>
                                    <CenterWrapper>
                                        <EasySvg />
                                    </CenterWrapper>
                                    <ServiceHeader>Easy to Use</ServiceHeader>
                                    <ServiceSubHeader>
                                        We have a user friendly system, easy to
                                        understand, convenient and simple
                                        Functionalities Explore our ecosystem to
                                        see how easy it is to use.
                                    </ServiceSubHeader>
                                </ServiceWrapper>
                                <ServiceWrapper>
                                    <CenterWrapper>
                                        <SecureSvg />
                                    </CenterWrapper>
                                    <ServiceHeader>
                                        Secure Transactions
                                    </ServiceHeader>
                                    <ServiceSubHeader>
                                        Our top priority is, and always will be,
                                        protecting you and your digital assets.
                                        Here are some simple ways you can add
                                        more protection to your account. Enable
                                        2FA, Gmail and SMS Authentications
                                    </ServiceSubHeader>
                                </ServiceWrapper>
                                <ServiceWrapper>
                                    <CenterWrapper>
                                        <RocketSvg />
                                    </CenterWrapper>
                                    <ServiceHeader>Super Fast</ServiceHeader>
                                    <ServiceSubHeader>
                                        Our system is greatly enhanced and well
                                        optimized to ensure that all
                                        transactions go swiftly within the
                                        eco-system.
                                    </ServiceSubHeader>
                                </ServiceWrapper>
                            </ServiceCard>
                        </Fade>
                    </Grid>
                </Grid>
            </Container>
        </RootStyle>
    );
};

export default Services;

const ServiceWrapper = styled(ColumnWrapper)(({theme}) => ({
    maxWidth: 300,
    [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
        paddingBottom: 50
    },
    [theme.breakpoints.down("sm")]: {
        paddingBottom: 30
    }
}));
const ServiceCard = styled(Card)(({theme}) => ({
    marginTop: 70,
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "100%",
    padding: "90px 60px",
    boxShadow: theme.customShadows.primary,
    [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        padding: 60
    }
}));
const ServiceHeader = styled(Typography)(({theme}) => ({
    ...theme.typography.h5,
    paddingTop: 20,
    color: theme.palette.primary.main,
    textAlign: "center"
}));
const ServiceSubHeader = styled(Typography)(({theme}) => ({
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    paddingTop: 5,
    textAlign: "center"
}));
