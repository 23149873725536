import {darken, lighten, styled, useTheme} from "@mui/material/styles";
import * as React from "react";
function BackgroundImage(props) {
    const theme = useTheme();
    const BACKGROUND = theme.palette.primary.main;
    const BACKGROUND_DARK = darken(BACKGROUND, 0.2);
    const BACKGROUND_LIGHT = lighten(BACKGROUND, 0.06);
    return (
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            height="100%"
            preserveAspectRatio="none"
            viewBox="0 0 1640 760"
            {...props}>
            <g mask='url("#SvgjsMask1008")' fill="none">
                <path fill={BACKGROUND} d="M0 0h1640v760H0z" />
                <path
                    d="M0 613.036c110.95 27.958 215.989-65.492 309.649-131.213 90.62-63.588 174.338-139.096 219.566-240.139C574.253 141.066 574.818 28.7 561.452-80.725c-13.595-111.302-19.459-244.11-109.584-310.821-90.939-67.313-220.871 4.252-332.156-16.155-94.673-17.361-171.299-104.551-266.938-93.706-100.507 11.397-198.391 69.052-254.984 152.89-54.769 81.137-31.394 187.397-40.511 284.863-8.598 91.912-54.951 189.642-11.495 271.087 43.252 81.062 162.349 78.886 230.881 140.083C-135.287 426.14-114.465 584.192 0 613.036"
                    fill={BACKGROUND_DARK}
                />
                <path
                    d="M1640 1294.422c119.067 21.348 248.408 75.133 355.087 18.104 107.22-57.318 146.205-190.772 184.044-306.313 34.724-106.028 35.662-216.199 23.395-327.092-12.631-114.178-21.181-236.209-95.407-323.882-74.514-88.014-196.66-108.115-304.629-148.629-117.011-43.907-234.316-134.814-353.895-98.476-119.568 36.335-165.845 177.568-239.23 278.718-66.682 91.911-162.41 173.423-170.949 286.653-8.411 111.539 68.234 207.968 126.446 303.483 52.049 85.403 110.264 165.805 193.426 221.365 84.202 56.255 182.036 78.198 281.712 96.069"
                    fill={BACKGROUND_LIGHT}
                />
            </g>
            <defs>
                <mask id="prefix__SvgjsMask1008">
                    <path fill="#fff" d="M0 0h1640v760H0z" />
                </mask>
            </defs>
        </Svg>
    );
}

export default BackgroundImage;

const Svg = styled("svg")(() => ({
    position: "absolute",
    top: 0,
    left: 0,
    margin: "auto",
    width: "100%",
    zIndex: -1
}));
